<template>
  <div>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <h3>{{ $t("Latest offers") }}</h3>
        <div class="d-flex gap-2">
          <button @click="showNext" class="bg-transparent slider__icon">
            <b-icon icon="arrow-right"></b-icon>
          </button>
          <button @click="showPrev" class="bg-transparent slider__icon">
            <b-icon icon="arrow-left"></b-icon>
          </button>
        </div>
      </div>

      <div  class="mt-3 offers">
        <VueSlickCarousel v-bind="settings" ref="carousel">
          <div
            v-for="(item, index) in offers"
            :key="index"
            class="offers__item ms-3"
          >
            <div class="position-relative w-100 ms-3">
              <div class="offers__item__overlay ">
                <div
                  class="p-4 d-flex flex-column justify-content-between h-100"
                >
                  <div class="d-flex flex-column gap-2 font-weight-bold">
                    <!-- <span class="offers__item__overlay__offer-text">
                      {{ item.value }}% OFF</span
                    > -->
                    <!-- <span class="text-white">
                      {{ $t("end") }} {{ $formatDateOffers(item.toDate) }}
                    </span> -->
                  </div>
                  <div class="d-flex gap-2 justify-content-between">
                    <span class="text-white font-weight-bold">
                      {{ $getByLang(item.name) }}
                    </span>
                    <router-link
                      :to="`/offers/${item._id}`"
                      class="offers__item__overlay__btn"
                    >
                      {{ $t("Shop now") }}
                    </router-link>
                  </div>
                </div>
              </div>
              <img
                class="offers__item__image w-100"
                :src="$baseUploadURL + item.offerImage"
                :alt="$getByLang(item.name)"
              />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel-rtl";
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-rtl.css";
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-theme.css";
export default {
  props: {
    offers: {
      type: Array,
      required: true,
    },
  },
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        rtl: this.$i18n.locale === "ar" ? true : false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1.8,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>
<style lang="scss" scoped>
.slider__icon {
  font-size: 24px;
}
.offers {
  &__item {
    padding: 12px;
    border-radius: 8px;
    &__overlay {
      border-radius: 8px;
      position: absolute;
      inset: 0;
      z-index: 1;
      background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      &__btn {
        background-color: #f59f00;
        color: white;
        padding: 8px 16px;
        border-radius: 25px;
        width: fit-content;
        text-decoration: none;
      }
      &__offer-text {
        background-color: #f59f00;
        color: white;
        padding: 8px 16px;
        border-radius: 25px;
        width: fit-content;
      }
    }
    &__image {
      height: 200px;
      display: block;
    }
  }
}
</style>
