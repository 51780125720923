<template>
  <div id="app">
    <!-- <div class="loding" v-if="loading">
      <div class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="four"></div>
      <div class="five"></div>
    </div> -->
    <Toast position="top-left" />
    <!-- <ConfirmDialog></ConfirmDialog> -->
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  },
};
</script>
<style>
.loding {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  z-index: 999999;
  background-color: #eee;
}
.one,
.two,
.three,
.four,
.five {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
  animation: loading 2.5s ease-in-out infinite;
}

@keyframes loading {
  0%,
  100% {
    transform: scale(0.2);
    background-color: #0ddcf8;
  }
  42% {
    background-color: #5d4da6;
  }
  50% {
    transform: scale(1);
    background-color: #42b58fb8;
  }
}

/* .loding:nth-child(0) {
  animation-delay: 0s;
}
.loding:nth-child(1) {
  animation-delay: 0.2s;
}
.loding:nth-child(2) {
  animation-delay: 0.4s;
}
.loding:nth-child(3) {
  animation-delay: 0.6s;
}
.loding:nth-child(4) {
  animation-delay: 0.8s;
}
.loding:nth-child(5) {
  animation-delay: 1s;
} */

/* ^ bad pract */
.one {
  animation-delay: 0s;
}
.two {
  animation-delay: 0.2s;
}
.three {
  animation-delay: 0.4s;
}
.four {
  animation-delay: 0.8s;
}
.five {
  animation-delay: 1s;
}
</style>
