import axios from 'axios';

export const baseURL = 'https://herehome.sa:7000';
// export const baseURL = 'http://localhost:7000';

export const baseUploadURL = baseURL + '/uploads/';

const user = localStorage.homeHere ? JSON.parse(localStorage.homeHere) : '';

export const HTTP = axios.create({
  baseURL: `${baseURL}/api/`,
  headers: {
    Authorization: `Bearer ${user?.token}`,
    lang: localStorage.lang || 'ar',
    userid: user?._id,
  },
});
