<template>
<div>
    <div class="container">
        <div class="d-flex align-items-center justify-content-between">
            <h3>{{ $t("categories") }}</h3>
            <div class="d-flex gap-2">
                <button @click="showNext" class="bg-transparent slider__icon">
                    <b-icon icon="arrow-right"></b-icon>
                </button>
                <button @click="showPrev" class="bg-transparent slider__icon">
                    <b-icon icon="arrow-left"></b-icon>
                </button>
            </div>
        </div>

        <div class="mt-3 categories">
            <VueSlickCarousel v-bind="settings" ref="carousel">
                <div v-for="(item, index) in products" :key="index">
                    <div class="ms-3 mb-3 categories__item">
                        <router-link :to="`/categories/${item._id}`" class="categories__item__text">

                            <div class="d-flex align-items-center ">
                                <div class="">
                                    <router-link :to="`/categories/${item._id}`" class="categories__item__text"></router-link>

                                    <img :src="$baseUploadURL + item.categoryImage" :alt="item.name" />
                                </div>
                                <div class="categories__item__text">{{ $getByLang(item.name) }}

                                </div>
                            </div>

                        </router-link>
                    </div>

                </div>
            </VueSlickCarousel>
        </div>
    </div>
</div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel-rtl";
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-rtl.css";
import "vue-slick-carousel-rtl/dist/vue-slick-carousel-theme.css";
export default {
    props: {
        products: {
            type: Array,
            required: true,
        },
    },
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            settings: {
                dots: false,
                arrows: false,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                rows: 1,
                cssEase: "linear",
                rtl: this.$i18n.locale === "ar" ? true : false,
                responsive: [{
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2.5,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1.8,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1.2,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
        };
    },
    methods: {
        showNext() {
            this.$refs.carousel.next();
        },
        showPrev() {
            this.$refs.carousel.prev();
        },
    },
};
</script>

<style lang="scss" scoped>
.slider__icon {
    font-size: 24px;
}

.categories {
    &__item {
        // background-color: #b62020;
        background-color: #f1f1f1;
        // border-radius: 8px;
        overflow: hidden;
        height: 155px;

        img {
            width: 210px;
            max-width: 210px;
            min-width: 210px;
            height: 155px;
            max-height: 155px;
            min-height: 155px;
            user-select: none;
            pointer-events: none;
            object-fit: contain;
        }

        &__text {
            font-weight: bold;
            color: #000;
            font-size: 19px;
            font-weight: 700;
        }
    }
}
</style>
