<template>
  <div>
    <footer class="text-center">
      <div class="container-fluid p-4">
        <section class="">
          <div class="row d-flex justify-content-center">
            <div class="foot col-xs-12 col-lg-2 col-12 mb-4 mb-md-0 p-md-0">
              <img
                class="foot-logo"
                src="../assets/images/logo-white.png"
                alt=""
              />
              <div>
                <p class="gray-color m-0">سجل تجاري رقم : 1010853756</p>
                <p class="gray-color m-0">توثيق رقم : 0000098510</p>
                <div >
                  <a href="https://www.saudibusiness.gov.sa/Identity/Account/Login?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DEAuthentication%26redirect_uri%3Dhttps%253A%252F%252FEAuthenticate.saudibusiness.gov.sa%252Fsignin-callback.html%26response_type%3Dcode%26scope%3Dbaladyapi%2520CrBaladyLicenseAPI%2520DataBroadCastApi%2520InvestorPortalApi%2520userprofileapis%2520EAuthenticateAPI%2520servicecatalogapis%2520requestapi%2520proxyapi%2520notificationapi%2520lookupapis%2520delegationapi%2520openid%2520profile%2520roles%2520AttachmentAPI%2520offline_access%26state%3D0cdacc0eab504502a285d500ac7d5cb8%26code_challenge%3DYVUVLE6IV-wUoRP4rd-TGSq0MlUcvjxkwzGuFyxtuUU%26code_challenge_method%3DS256%26response_mode%3Dquery" target="_blank">
                    <img style="width:180px" src="../assets/images/saudiBusiness.png" alt="" />
                  </a>
                </div>
              </div>
              <p class="foot-p m-0">
                {{ $t("followUs") }}
              </p>
              <div class="icons icons-foot-mob">
                <div class="social linkedin">
                  <a :href="settings.linkedinLink" target="_blank">
                    <img src="../assets/images/Group 13.png" alt="" />
                  </a>
                </div>
                <div class="social twitter">
                  <a :href="settings.twitterLink" target="_blank">
                    <img src="../assets/images/Group 12.png" alt="" />
                  </a>
                </div>

                <div class="social instagram">
                  <a :href="settings.instegramLink" target="_blank">
                    <img src="../assets/images/Group 11.png" alt="" />
                  </a>
                </div>
                <div class="social facebook">
                  <a :href="settings.facebookLink" target="_blank">
                    <img src="../assets/images/Group 10.png" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div class="foot col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5">
              <h5 class="text-uppercase">{{ $t("Important links") }}</h5>

              <ul class="mt-3">
                <li>
                  <router-link to="/">{{ $t("Home") }}</router-link>
                </li>
                <li>
                  <router-link to="/orders">{{ $t("orders") }}</router-link>
                </li>
                <li>
                  <router-link to="/myfavorite">{{
                    $t("Favorite")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/helpcenter">{{
                    $t("helpcenter")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/vendorsRequests">{{
                    $t("vendorsRequests")
                  }}</router-link>
                </li>
              </ul>
            </div>

            <div class="foot col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5">
              <h5 class="text-uppercase">{{ $t("categories") }}</h5>

              <ul class="mt-3">
                <li v-for="item of catList">
                  <router-link :to="'/categories/' + item._id">{{ $getByLang(item.name) }}</router-link>
                </li>
              </ul>
            </div>

            <div class="foot col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5">
              <h5 class="text-uppercase">{{ $t("company") }}</h5>

              <ul class="mt-3">
                <li>
                  <router-link to="/page/aboutUs">{{
                    $t("about us")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/page/policy">{{
                    $t("privacy policy")
                  }}</router-link>
                </li>

                <li>
                  <router-link to="/page/termsAndConditions">{{
                    $t("Terms and Conditions")
                  }}</router-link>
                </li>
                <li>
                  <router-link to="/helpcenter?to=contact">{{
                    $t("Connect us")
                  }}</router-link>
                </li>
              </ul>
            </div>

            <div
              class="foot google-play col-lg-2 col-md-6 col-xs-6 mb-4 mb-md-0 mt-5"
            >
              <h5 class="text-uppercase">
                {{ $t("to download application") }}
              </h5>

              <ul class="mt-3">
                <li>
                  <a :href="settings.googlePlayLink" target="_blank">
                    <img src="../assets/images/Google Play badge.png" alt="" />
                  </a>
                </li>
                <li>
                  <a :href="settings.appleStoreLink" target="_blank">
                    <img src="../assets/images/App Store badge.png" alt="" />
                  </a>
                </li>
                <li>
                  <a :href="settings.huaweiLink" target="_blank">
                    <img
                      src="../assets/images/huawei badge.png"
                      alt=""
                      style="width: 135px"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div class="border-top pt-3 mt-3">
          <div
            class="row align-items-center justify-content-md-between justify-content-center"
          >
            <div class="col-md-1 col-4">
              <img src="../assets/payment/1.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/2.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/3.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/4.png" height="50" width="50" />
            </div>
            <div class="col-md-1 col-4">
              <img src="../assets/payment/5.png" height="50" width="50" />
            </div>
            <!-- <div class="col-md-1 col-4">
              <img src="../assets/payment/tabby.png" height="50" width="50" />
            </div> -->
            <div class="col-md-1 col-4">
              <img src="../assets/payment/tamara.png" height="50" width="50" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "app-footer",

  props: {
    list: []
  },
  components: { router },
  data() {
    return {
      catList: [],
      settings: {
        footerText: null,
        facebookLink: null,
        linkedinLink: null,
        twitterLink: null,
        instegramLink: null,
        googlePlayLink: null,
        appleStoreLink: null,
        websiteEmail: null,
        taxValue: null
      }
    };
  },
  created() {
    this.$http.get("categories?limit=4").then((res) => {
      this.catList = res.data.docs;
    });
    this.$http.get("settings?limit=4").then((res) => {
      this.settings =
        res.data.docs.length > 0
          ? res.data.docs[0]
          : {
              footerText: null,
              facebookLink: null,
              linkedinLink: null,
              twitterLink: null,
              instegramLink: null,
              googlePlayLink: null,
              appleStoreLink: null,
              websiteEmail: null,
              taxValue: null
            };
    });
  }
};
</script>

<style>
footer {
  background-color: #313131;
}

footer section div h5 {
  color: #d9d9d9;
}
ul {
  list-style: none;
  /* padding-left: initial; */
}

footer section div ul li a {
  text-decoration: none;
  color: #7a7a7a;
  font-size: 14px;
  line-height: 40px;
}
img.foot-logo {
  width: -webkit-fill-available;
}
@media (max-width: 768px) {
  img.foot-logo {
    width: 50%;
    margin: 10px auto;
  }
}
.gray-color {
  color: #bfbfbf;
  font-size: 16px;
  line-height: 40px;
}
.foot-p {
  color: #bfbfbf;
  font-size: 16px;
  line-height: 40px;
}
div.icons .social {
  color: #313131;
  font-size: 20px;
}
.social {
  display: inline-block;
  margin: 2px;
}

div.icons .social img {
  max-width: 30px;
}

.foot h5 {
  color: #d9d9d9;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.02em;
}
.google-play ul li {
  margin-bottom: 16px;
}
.social.linkedin {
  padding-top: 9px;
}
</style>
